import {
  widget,
  type Bar,
  type ChartingLibraryWidgetOptions,
  type ColorTypes,
  type ErrorCallback,
  type HistoryCallback,
  type IBasicDataFeed,
  type IChartingLibraryWidget,
  type ISubscription,
  type LanguageCode,
  type LibrarySymbolInfo,
  type OnReadyCallback,
  type PeriodParams,
  type ResolutionString,
  type ResolveCallback,
  type SearchSymbolsCallback,
  type StudyOverrides,
  type SubscribeBarsCallback,
  type Timezone,
  type WidgetOverrides,
} from '@trading-view/charting_library';
import Big from 'big.js';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
  type PropsWithChildren,
} from 'react';
import { useTheme } from 'styled-components';

import { useSecuritiesContext } from '../../contexts';
import { useDynamicCallback, useIntlContext, useSubscription } from '../../hooks';
import { setAlpha } from '../../styles';
import type { Security } from '../../types';
import { formattedDateForSubscription, Get, urlFromLocalOrAbsolute } from '../../utils';
import { Spinner, SpinnerContainer } from '../Spinner';
import { Chart } from './styles';

const TV_TO_SUB_RESOLUTIONS = {
  '1': '1M',
  '5': '5M',
  '60': '1H',
  '240': '4H',
};

const SUPPORTED_RESOLUTIONS = ['1', '5', '60', '240', '1D'] as ResolutionString[];

const STATE = 'tradingView.autosave';

const useTradingViewDatafeed = (params: TradingViewChartProps) => {
  const [request, setRequest] = useState<any>();
  const { data: sub } = useSubscription(request);
  const subscription = useRef<any>();
  const lastBar: MutableRefObject<Bar | null> = useRef(null);
  const { securitiesBySymbol } = useSecuritiesContext();

  const onReady = useCallback((callback: OnReadyCallback) => {
    setTimeout(() =>
      callback({
        supported_resolutions: SUPPORTED_RESOLUTIONS,
      })
    );
  }, []);

  const searchSymbols = useCallback(
    (userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) => {
      // console.log('searchSymbols', userInput, exchange, symbolType, onResult);
    },
    []
  );

  const resolveSymbol = useCallback(
    (symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback) => {
      const { DefaultPriceIncrement, Description } =
        securitiesBySymbol.get(symbolName) ??
        ({ DefaultPriceIncrement: '0.01', MinPriceIncrement: '0.01' } as Security);
      const pricescale = Big(1).div(DefaultPriceIncrement).toNumber();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;

      const info: LibrarySymbolInfo = {
        name: params.symbol ?? symbolName,
        ticker: params.symbol ?? symbolName,
        description: Description,
        type: 'security',
        supported_resolutions: SUPPORTED_RESOLUTIONS,
        has_intraday: true,
        session: '24x7',
        exchange: params.exchangeName,
        listed_exchange: params.exchangeName,
        timezone,
        format: 'price',
        minmov: 1,
        pricescale,
        minmove2: 0,
      };

      setTimeout(() => {
        onResolve(info);
      }, 0);
    },
    [params.exchangeName, params.symbol, securitiesBySymbol]
  );

  const getBars = useCallback(
    (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      periodParams: PeriodParams,
      onResult: HistoryCallback,
      onError: ErrorCallback
    ) => {
      const startDate = Sugar.Date.create(periodParams.from * 1000);
      const endDate = Sugar.Date.create(periodParams.to * 1000);
      const subResolution = TV_TO_SUB_RESOLUTIONS[resolution] || resolution;
      const markets = params?.markets ?? [];
      const marketsPart = markets.length > 0 ? `/markets/${encodeURIComponent(markets.join(','))}` : '';
      const sd = formattedDateForSubscription(startDate);
      const ed = formattedDateForSubscription(endDate);
      Get(
        urlFromLocalOrAbsolute(params.apiEndpoint),
        `/symbols/${params.symbol}${marketsPart}/ohlcv/${subResolution}?startDate=${sd}&endDate=${ed}`
      )
        .then(res => {
          const bars: Bar[] = res.data.map(d => {
            const empty = [d.Open, d.High, d.Low, d.Close].every(v => v === '0');
            return {
              time: new Date(d['Timestamp']).getTime(),
              open: !empty ? parseFloat(d['Open']) : undefined,
              high: !empty ? parseFloat(d['High']) : undefined,
              low: !empty ? parseFloat(d['Low']) : undefined,
              close: !empty ? parseFloat(d['Close']) : undefined,
              volume: !empty ? parseFloat(d['Volume']) : undefined,
            };
          });

          const noData = !res.data.length;
          const meta = {
            noData: noData,
            nextTime: noData ? Sugar.Date.create('5 seconds from now').getTime() / 1000 : null,
          };
          const last = bars.at(-1);
          if (last != null && (lastBar.current == null || last.time > lastBar.current.time)) {
            lastBar.current = { ...last };
          }
          onResult(bars, meta);
        })
        .catch(() => {
          onError('An unexpected error occurred.');
        });

      setRequest({
        name: 'OHLCV',
        tag: `OHLCV-${params.symbol}-${subResolution}`,
        Markets: params?.markets ?? [],
        Symbol: params.symbol,
        Resolution: subResolution,
      });
    },
    [params.symbol, params.markets, params.apiEndpoint]
  );

  const subscribeBars = useCallback(
    (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback) => {
      subscription.current = sub.subscribe(res => {
        const bars = res.data
          .map(d => {
            return {
              time: new Date(d['Timestamp']).getTime(),
              open: parseFloat(d['Open']),
              high: parseFloat(d['High']),
              low: parseFloat(d['Low']),
              close: parseFloat(d['Close']),
              volume: parseFloat(d['Volume']),
            };
          })
          .filter((bar: Bar) => {
            return (
              bar.time > (lastBar.current?.time || 0) ||
              (bar.time === lastBar.current?.time && (bar?.volume || 0) > (lastBar.current?.volume || 0))
            );
          });

        bars.forEach((bar: Bar) => onTick(bar));
      });
    },
    [sub]
  );

  const unsubscribeBars = useCallback(() => {}, []);

  return useMemo(
    () => ({
      onReady,
      searchSymbols,
      resolveSymbol,
      getBars,
      subscribeBars,
      unsubscribeBars,
    }),
    [onReady, searchSymbols, resolveSymbol, getBars, subscribeBars, unsubscribeBars]
  );
};

export interface TradingViewChartProps {
  symbol: string | null;
  markets?: string[];
  apiEndpoint: string;
  resolution?: ResolutionString;
  onResolutionChange?: (newInterval: ResolutionString) => void;
  chartId: string;
  /** The exchange name to appear in the Object Tree for this chart.
   *  For Whitelabel, this is the client name.
   *  For Ava, this is currently 'talos' */
  exchangeName: string;
}

export const TradingViewChart = (params: PropsWithChildren<TradingViewChartProps>) => {
  const [isLoading, setIsLoading] = useState(true);
  const interval = useRef<string>(params.resolution ?? '60');
  const tradingviewWidget = useRef<IChartingLibraryWidget | null>(null);
  const datafeed: IBasicDataFeed = useTradingViewDatafeed(params);
  const theme = useTheme();
  const { children, onResolutionChange } = params;
  const { locale } = useIntlContext();

  const getChartId = useDynamicCallback(() => {
    return `${STATE}/${params.chartId}`;
  });

  const onAutoSaveNeeded = useCallback(() => {
    tradingviewWidget.current?.save(state => localStorage.setItem(getChartId(), JSON.stringify(state)));
  }, [tradingviewWidget, getChartId]);

  const handleIntervalChange = useDynamicCallback((newInterval: ResolutionString) => {
    datafeed.unsubscribeBars('');
    interval.current = newInterval;
    onResolutionChange?.(newInterval);
  });

  useEffect(() => {
    let intervalChangedSub: ISubscription<(newInterval: ResolutionString) => void>;

    if (params.symbol != null) {
      setIsLoading(true);
      const {
        name,
        baseSize,
        backgroundChart,
        backgroundChartUpColor,
        backgroundChartDownColor,
        borderColorChartUpColor,
        borderColorChartDownColor,
        borderColorChartCrosshair,
        borderColorChartGridLine,
        colorTextDefault,
        fontSizeSmall,
        colors,
      } = theme;
      const overrides: Partial<WidgetOverrides> = {
        'paneProperties.background': backgroundChart,
        'paneProperties.backgroundType': 'solid' as ColorTypes,
        'paneProperties.vertGridProperties.color': borderColorChartGridLine,
        'paneProperties.horzGridProperties.color': borderColorChartGridLine,
        'paneProperties.crossHairProperties.color': borderColorChartCrosshair,
        'paneProperties.crossHairProperties.width': 1,

        'scalesProperties.backgroundColor': backgroundChart,
        'scalesProperties.fontSize': baseSize * fontSizeSmall,
        'scalesProperties.lineColor': borderColorChartGridLine,
        'scalesProperties.textColor': colorTextDefault,
        'scalesProperties.axisHighlightColor': setAlpha(0.25, colors.blue.lighten),
        'scalesProperties.showSeriesLastValue': false,

        'scalesProperties.axisLineToolLabelBackgroundColorActive': colors.blue.lighten,
        'scalesProperties.axisLineToolLabelBackgroundColorCommon': colors.blue.lighten,

        'paneProperties.legendProperties.showStudyArguments': false,
        'paneProperties.legendProperties.showStudyTitles': true,
        'paneProperties.legendProperties.showSeriesTitle': false,

        'mainSeriesProperties.candleStyle.upColor': backgroundChartUpColor,
        'mainSeriesProperties.candleStyle.downColor': backgroundChartDownColor,
        'mainSeriesProperties.candleStyle.borderColor': borderColorChartUpColor,
        'mainSeriesProperties.candleStyle.borderUpColor': borderColorChartUpColor,
        'mainSeriesProperties.candleStyle.borderDownColor': borderColorChartDownColor,
        'mainSeriesProperties.candleStyle.wickUpColor': borderColorChartUpColor,
        'mainSeriesProperties.candleStyle.wickDownColor': borderColorChartDownColor,

        'mainSeriesProperties.barStyle.upColor': backgroundChartUpColor,
        'mainSeriesProperties.barStyle.downColor': backgroundChartDownColor,
      };

      const studiesOverrides: Partial<StudyOverrides> = {
        'volume.volume.color.0': borderColorChartDownColor,
        'volume.volume.color.1': borderColorChartUpColor,
      };
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;
      const widgetOptions: ChartingLibraryWidgetOptions = {
        debug: false,
        library_path: `/tradingview/charting_library/`,
        container: containerRef.current as HTMLElement,
        datafeed: datafeed,
        symbol: params.symbol,
        interval: interval.current as ResolutionString,
        locale: locale as LanguageCode,
        autosize: true,
        theme: name.includes('Dark') ? 'dark' : 'light',
        custom_css_url: `/tradingview/${name}.css`,
        custom_font_family: theme.fontFamily,
        disabled_features: [
          'header_symbol_search',
          'header_chart_type',
          'header_settings',
          'header_indicators',
          'header_compare',
          'header_undo_redo',
          'header_screenshot',
          'header_fullscreen_button',
          'context_menus',
          'legend_context_menu',
          'pane_context_menu',
          'control_bar',
          'timeframes_toolbar',
          'main_series_scale_menu',
          'edit_buttons_in_legend',
          'display_market_status',
        ],
        timezone,
        overrides,
        studies_overrides: studiesOverrides,
        auto_save_delay: 1,
      };

      tradingviewWidget.current = new widget(widgetOptions);
      tradingviewWidget.current.onChartReady(() => {
        if (!tradingviewWidget.current) {
          return;
        }
        const savedState = localStorage.getItem(getChartId());
        if (savedState) {
          // What we save to local storage includes the user's system timezone info as it was at the time of saving.
          // This causes problems if the user then changes their system timezone as their chart continue to display in the previous timezone.
          // The below searches for the timezone key in the saved state and replaces it with the current timezone.
          // This is flakey in that we do not control the format of this state, however the string replacement is less flakey than finding the exact location in the object and replacing it there (allowing tradingview to move the location at least and this will still work).
          const processedState = savedState.replace(/"timezone":"[^"]+"/, `"timezone":"${timezone}"`);
          tradingviewWidget.current.load(JSON.parse(processedState));
        }
        setIsLoading(false);
        tradingviewWidget.current.subscribe('onAutoSaveNeeded', onAutoSaveNeeded);
        tradingviewWidget.current.applyOverrides(overrides);
        const chart = tradingviewWidget.current.activeChart();
        intervalChangedSub = chart.onIntervalChanged();
        intervalChangedSub.subscribe(null, handleIntervalChange);
        const series = chart.getSeries();
        chart.executeActionById('chartReset');
        series.setChartStyleProperties(1, {
          upColor: backgroundChartUpColor,
          downColor: backgroundChartDownColor,
          drawWick: true,
          drawBorder: true,
          borderColor: '#aaa',
          borderUpColor: borderColorChartUpColor,
          borderDownColor: borderColorChartDownColor,
          wickColor: '#aaa',
          wickUpColor: borderColorChartUpColor,
          wickDownColor: borderColorChartDownColor,
          barColorsOnPrevClose: true,
        });
      });
    }

    return () => {
      if (tradingviewWidget.current) {
        datafeed.unsubscribeBars('');
        intervalChangedSub?.unsubscribe(null, handleIntervalChange);
        tradingviewWidget.current.remove();
        tradingviewWidget.current = null;
      }
    };
  }, [theme, params.symbol, datafeed, onAutoSaveNeeded, handleIntervalChange, getChartId, locale]);

  const containerRef = useRef<HTMLDivElement>(null);

  if (params.symbol == null) {
    return <Spinner />;
  }

  return (
    <>
      {isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      <Chart isHidden={isLoading} ref={containerRef} />
      {children}
    </>
  );
};
