import {
  CustomerUserConfigLayoutNavigationTypes,
  CustomerUserConfigLayoutType,
  Dialog,
  FormControlSizes,
  FormGroup,
  IconName,
  SearchSelect,
  ThemeToggle,
  Toggle,
  WL_INITIATE_ORDER,
  WL_INITIATE_RFQ,
  WL_SHOW_CANDLESTICKS_AND_MARKETDATA,
  WL_VIEW_BLOTTERS_BALANCES,
  getStringLabel,
  useDynamicCallback,
  useWLOrgConfigContext,
  type CustomerUserConfigNavigationItem,
  type DialogProps,
  type ThemeTypes,
  type WLOrgConfig,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';

type ThemeOption = {
  label: string;
  value: string;
  logo: WLOrgConfig['logo'];
  collapsedLogo: WLOrgConfig['collapsedLogo'];
};

export const DemoSettingsDialog = ({
  themeType,
  onChangeThemeType,
  ...props
}: DialogProps & {
  themeType: ThemeTypes;
  onChangeThemeType: (themeType: ThemeTypes) => void;
}) => {
  const { overrides, setOverrides } = useWLOrgConfigContext();
  const [navigationOptions] = useState(
    Object.keys(CustomerUserConfigLayoutNavigationTypes) as Array<CustomerUserConfigLayoutNavigationTypes>
  );

  const [layoutOptions] = useState(Object.keys(CustomerUserConfigLayoutType));

  const [themeOptions] = useState<ThemeOption[]>([
    {
      label: 'CryptoCo OTC',
      value: 'DarkTalosTheme',
      logo: { dark: 'cryptoco-otc-logo-d.svg', light: 'cryptoco-otc-logo-l.svg' },
      collapsedLogo: { dark: 'cryptoco-otc-icon.svg', light: 'cryptoco-otc-icon.svg' },
    },
    {
      label: 'Transparency',
      value: 'DarkTransparencyTheme',
      logo: { dark: 'transparency-logo-dark.svg', light: 'transparency-logo-light.svg' },
      collapsedLogo: { dark: 'transparency-collapsed-logo-dark.svg', light: 'transparency-collapsed-logo-light.svg' },
    },
    {
      label: 'Gradients',
      value: 'DarkGradientsTheme',
      logo: { dark: 'gradients-logo.svg', light: 'gradients-logo.svg' },
      collapsedLogo: { dark: 'gradients-collapsed-logo.svg', light: 'gradients-collapsed-logo.svg' },
    },
    {
      label: 'Conservative',
      value: 'DarkConservativeTheme',
      logo: { dark: 'conservative-logo-dark.svg', light: 'conservative-logo-light.svg' },
      collapsedLogo: { dark: 'conservative-collapsed-logo-dark.svg', light: 'conservative-collapsed-logo-light.svg' },
    },
  ]);

  const handleThemeChange = useCallback(
    (value?: ThemeOption) => {
      if (value?.value) {
        setOverrides({ ...overrides, theme: value?.value, logo: value?.logo, collapsedLogo: value?.collapsedLogo });
      } else {
        delete overrides.theme;
        delete overrides.logo;
        delete overrides.collapsedLogo;
        setOverrides({ ...overrides });
      }
    },
    [overrides, setOverrides]
  );

  const handleLayoutChange = useDynamicCallback((value: string | undefined) => {
    if (value) {
      setOverrides({ ...overrides, layout: CustomerUserConfigLayoutType[value] });
    } else {
      delete overrides.layout;
      setOverrides({ ...overrides });
    }
  });

  // We only want to show the trading layout.
  const exampleNavItems = useMemo(
    () =>
      EXAMPLE_NAV_ITEMS.filter(ni => {
        return ni.layoutComponent !== CustomerUserConfigLayoutType.TradingLayout;
      }),
    []
  );

  return (
    <Dialog {...props} width={300} autoFocusFirstElement={false}>
      <FormGroup label="Navigation Type">
        <SearchSelect
          showClear={true}
          options={navigationOptions}
          selection={overrides.navigationType}
          onChange={value => setOverrides({ ...overrides, navigationType: value })}
          getLabel={getStringLabel}
          portalize={true}
        />
      </FormGroup>
      <FormGroup label="Theme">
        <SearchSelect
          showClear={true}
          getLabel={o => o.label}
          options={themeOptions}
          selection={themeOptions.find(to => to.value === overrides.theme)}
          onChange={handleThemeChange}
          portalize={true}
        />
      </FormGroup>
      <FormGroup label="Layout">
        <SearchSelect
          showClear={true}
          getLabel={getStringLabel}
          options={layoutOptions}
          selection={overrides.layout}
          onChange={handleLayoutChange}
          portalize={true}
        />
      </FormGroup>
      <FormGroup inline label="Theme type" justifyContent="space-between">
        <ThemeToggle themeType={themeType} onChangeThemeType={onChangeThemeType} size={FormControlSizes.Small} />
      </FormGroup>
      <FormGroup inline label="Show menu items" justifyContent="space-between" alignItems="center">
        <Toggle
          checked={!!overrides.navigationItems}
          onChange={checked => setOverrides({ ...overrides, navigationItems: checked ? exampleNavItems : undefined })}
        />
      </FormGroup>
      <FormGroup inline label="Order form toggle" justifyContent="space-between" alignItems="center">
        <Toggle
          checked={!!overrides.orderFormToggle}
          onChange={checked => setOverrides({ ...overrides, orderFormToggle: checked })}
        />
      </FormGroup>
      <FormGroup inline label="Show spread" justifyContent="space-between" alignItems="center">
        <Toggle
          checked={!!overrides.showSpreadOnVolumeLadder}
          onChange={checked => setOverrides({ ...overrides, showSpreadOnVolumeLadder: checked })}
        />
      </FormGroup>
    </Dialog>
  );
};

const EXAMPLE_NAV_ITEMS: CustomerUserConfigNavigationItem[] = [
  {
    label: 'Market',
    path: '/',
    icon: IconName.ChartBarBox,
    requiredPermissions: [[WL_INITIATE_ORDER, WL_INITIATE_RFQ], WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
  },
  {
    label: 'Trading',
    path: '/trading',
    layoutComponent: CustomerUserConfigLayoutType.TradingLayout,
    icon: IconName.ChartLine,
    requiredPermissions: [[WL_INITIATE_ORDER, WL_INITIATE_RFQ], WL_SHOW_CANDLESTICKS_AND_MARKETDATA],
  },
  {
    label: 'Portfolio',
    path: '/portfolio',
    icon: IconName.ChartDonut,
    layoutComponent: CustomerUserConfigLayoutType.PortfolioLayout,
    requiredPermissions: [WL_VIEW_BLOTTERS_BALANCES],
  },
  {
    label: 'Settings',
    icon: IconName.Cog,
    path: '/settings',
    layoutComponent: CustomerUserConfigLayoutType.SettingsLayout,
    requiredPermissions: ['settings.view'],
  },
  {
    label: 'Talos',
    icon: IconName.PresentationChartBar,
    path: '//talos.com',
    target: '_self',
    requiredPermissions: ['settings.view'],
  },
  {
    label: 'API docs',
    icon: IconName.ExternalLink,
    path: '//docs.talostrading.com',
    requiredPermissions: ['settings.view'],
  },
];
