import {
  Box,
  Button,
  FormControlSizes,
  FormattedMessage,
  Icon,
  IconButton,
  IconName,
  LocaleSelector,
  MixpanelEvent,
  MixpanelEventSource,
  NavTab,
  SymbolDisplay,
  SymbolSelector,
  SymbolSelectorAppearance,
  TabList,
  TabSize,
  Tabs,
  ToggleButton,
  WL_USE_SYMBOL_SELECTOR,
  getIntlKey,
  selectIsFormOpen,
  setOpenForm,
  themes,
  useIntl,
  useIntlContext,
  useMixpanel,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWLOrgConfigContext,
  useWLRoleAuth,
  useWLSymbol,
  type CustomerUserConfigNavigationItem,
  type IntlWithFormatter,
  type ThemeTypes,
} from '@talos/kyoko';
import { FundingSchedule } from 'components/FundingSchedule';
import { navSidebarMessages } from 'components/NavigationSidebar';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { ThemeProvider, useTheme, type DefaultTheme } from 'styled-components';
import { useNavigationItems } from '../../../hooks/useNavigationItems';
import { CreditUtilization } from '../../CreditUtilization';
import { Logo } from '../../Logo';
import { EditLayoutButton } from '../Header/EditLayoutButton';
import { useCurrentLayoutComponent } from '../useCurrentLayoutComponent';
import { UserMenu } from './UserMenu';
import { HEADER_CONTENT_HEIGHT, HeaderContent, NavigationHeaderWrapper, Section } from './styles';

const messages = defineMessages({
  orderForm: {
    id: 'Layout.NavigationHeader.orderForm',
    defaultMessage: 'Order Form',
  },
  support: {
    id: 'Layout.NavigationHeader.support',
    defaultMessage: 'Support',
  },
});

interface NavigationHeaderProps {
  showSymbolSelector: boolean;
  themeType: ThemeTypes;
  maxWidth?: number;
  compact: boolean;
  sidebarToggle: boolean;
  isTalosWLDemoEnv: boolean;
  drawerOpen: boolean;
  openDrawer?: () => void;
  openDemoModal?: () => void;
  openSupportModal?: () => void;
  onChangeThemeType: (themeType: ThemeTypes) => void;
}

export function NavigationHeader({
  showSymbolSelector,
  maxWidth,
  themeType,
  compact,
  sidebarToggle,
  drawerOpen,
  isTalosWLDemoEnv,
  openDrawer,
  openDemoModal,
  openSupportModal,
  onChangeThemeType,
}: NavigationHeaderProps) {
  const { config } = useWLOrgConfigContext();
  const formsOpen = useWLOrderFormSelector(selectIsFormOpen);
  const dispatch = useWLOrderFormDispatch();
  const setFormsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setOpenForm(isOpen));
    },
    [dispatch]
  );
  const { symbol, setSymbol } = useWLSymbol();
  const { isAuthorized } = useWLRoleAuth();
  const history = useHistory();
  const layoutComponent = useCurrentLayoutComponent();
  const intl = useIntl();

  const tabItems = useNavigationItems();
  const menuItems = config.menuItems;

  const mixpanel = useMixpanel();

  const handleSymbolChange = useCallback(
    (symbol?: string) => {
      mixpanel.track(MixpanelEvent.ChangeSymbol, { source: MixpanelEventSource.Header });
      if (symbol) {
        setSymbol(symbol);
      }
    },
    [mixpanel, setSymbol]
  );

  const location = useLocation();
  const selectedIndex = tabItems.findIndex(ti => ti['path'] && ti['path'] === location.pathname);

  const hasNavItems = tabItems.length > 1 || (menuItems?.length ?? 0) > 0;

  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';

  const theme = useTheme();
  const headerTheme = ((theme.headerThemeName && (themes as any)[theme.headerThemeName]) || theme) as DefaultTheme;
  const { locale, setLocale } = useIntlContext();

  return (
    <ThemeProvider theme={headerTheme}>
      <NavigationHeaderWrapper pb={hasNavItems && !compact && !sidebarToggle ? 0 : undefined}>
        <HeaderContent maxWidth={hasNavItems ? undefined : maxWidth}>
          {sidebarToggle && (
            <Box ml={!maxWidth ? '-spacingComfortable' : ''}>
              <IconButton
                ghost
                disabled={drawerOpen}
                onClick={openDrawer}
                icon={IconName.Menu}
                size={FormControlSizes.Large}
                color="colorTextImportant"
              />
            </Box>
          )}
          <Section onClick={() => history.push('/')}>
            <Logo themeType={theme.type} height="32px" />
          </Section>
          {layoutComponent.showOrderFormToggle === 'left' && (
            <Section>
              <ToggleButton selected={formsOpen} onClick={() => setFormsOpen(!formsOpen)}>
                <FormattedMessage {...messages.orderForm} />
              </ToggleButton>
            </Section>
          )}
          {showSymbolSelector ? (
            <Section alignItems="start">
              {!isAuthorized(WL_USE_SYMBOL_SELECTOR) ? (
                <SymbolDisplay symbol={symbol} />
              ) : (
                <SymbolSelector
                  appearance={SymbolSelectorAppearance.Ghost}
                  symbol={symbol}
                  onSymbolChanged={handleSymbolChange}
                  dropdownWidth="200px"
                />
              )}
            </Section>
          ) : null}

          {hasNavItems && compact && (
            <Section>
              <NavigationTabs selectedIndex={selectedIndex} tabItems={tabItems} intl={intl} />
            </Section>
          )}
          <Section ml="auto">
            <CreditUtilization />
          </Section>
          {isTalosWLDemoEnv && <LocaleSelector locale={locale} setLocale={setLocale} />}
          {sidebarToggle && isTalosWLDemoEnv && (
            <Section>
              <EditLayoutButton />
            </Section>
          )}
          {sidebarToggle && (layoutComponent.showOrderFormToggle === 'right' || config.orderFormToggle) && (
            <Section>
              <ToggleButton selected={formsOpen} onClick={() => setFormsOpen(!formsOpen)}>
                <FormattedMessage {...messages.orderForm} />
              </ToggleButton>
            </Section>
          )}
          {!sidebarToggle && (
            <Section>
              <Button prominent startIcon={IconName.Support} onClick={openSupportModal}>
                <FormattedMessage {...messages.support} />
              </Button>
              {isTalosWLDemoEnv && <EditLayoutButton />}
              <FundingSchedule />
              {isTalosWLDemoEnv && (
                <IconButton prominent icon={IconName.PresentationChartBar} onClick={openDemoModal} />
              )}
              {(layoutComponent.showOrderFormToggle === 'right' || config.orderFormToggle) && (
                <ToggleButton selected={formsOpen} onClick={() => setFormsOpen(!formsOpen)}>
                  <FormattedMessage {...messages.orderForm} />
                </ToggleButton>
              )}
              <UserMenu
                themeType={themeType}
                onChangeThemeType={onChangeThemeType}
                showThemeToggle={config?.allowThemeChange !== false}
                showLayoutToggle={!tabItems && (!isProd || !!config?.allowLayoutChange)}
              />
            </Section>
          )}
        </HeaderContent>
        {hasNavItems && !compact && !sidebarToggle && (
          <NavigationTabs selectedIndex={selectedIndex} tabItems={tabItems} intl={intl} />
        )}
      </NavigationHeaderWrapper>
    </ThemeProvider>
  );
}

function NavigationTabs({
  selectedIndex,
  tabItems,
  intl,
}: {
  selectedIndex: number;
  tabItems: CustomerUserConfigNavigationItem[];
  intl: IntlWithFormatter;
}) {
  const { spacingDefault } = useTheme();
  return (
    <Tabs pt={spacingDefault} selectedIndex={selectedIndex} size={TabSize.Large}>
      <TabList suppressOverflowList={true} h={HEADER_CONTENT_HEIGHT + spacingDefault}>
        {tabItems.map(ti => {
          const isExternal = ti.path.startsWith('//') || ti.path.startsWith('http');
          const intlKey = getIntlKey(ti.label);
          const label = isNavSideBarMessage(intlKey) ? intl.formatMessage(navSidebarMessages[intlKey]) : ti.label;
          return (
            <NavTab
              key={ti.label}
              label={label}
              to={ti.path}
              suffix={isExternal && ti.icon && <Icon icon={ti.icon} />}
              target={isExternal ? ti.target ?? '_blank' : undefined}
              rel={isExternal ? 'noreferrer' : undefined}
            />
          );
        })}
      </TabList>
    </Tabs>
  );
}

const isNavSideBarMessage = (key: string): key is keyof typeof navSidebarMessages => key in navSidebarMessages;
