import { Link } from 'react-router-dom';
import type { DefaultTheme } from 'styled-components';
import styled, { css, keyframes } from 'styled-components';
import { parseDimension } from 'styles';
import { Box, HStack, VStack } from '../Core';
import { Icon } from '../Icons';
import { TabAppearance, TabSize, type TabProps } from './types';

export const Wrapper = styled(VStack)``;
Wrapper.defaultProps = {
  alignItems: 'initial',
  flex: '0 1 auto',
  w: '100%',
  overflow: 'hidden',
  justifyContent: 'initial',
};

function getTabHeight(
  theme: DefaultTheme,
  appearance: TabAppearance = TabAppearance.Underlined,
  size: TabSize = TabSize.Default
): string {
  let height = theme.baseSize * size;
  if (appearance === TabAppearance.Pill) {
    // Adjust for border around the pill
    height = height - 2 * theme.spacingTiny;
  }
  return `${height}px`;
}

function getTabListHeight(theme: DefaultTheme, size: TabSize = TabSize.Default): string {
  return `${theme.baseSize * size}px`;
}

function getFontSize(
  theme: DefaultTheme,
  appearance: TabAppearance = TabAppearance.Underlined,
  size: TabSize = TabSize.Default
): string {
  if (appearance === TabAppearance.Pill) {
    return size === TabSize.Small ? `${theme.fontSizeTiny}rem` : `${theme.fontSizeSmall}rem`;
  }
  return size === TabSize.Small ? `${theme.fontSizeSmall}rem` : `${theme.fontSizeDefault}rem`;
}

function getPadding(
  theme: DefaultTheme,
  appearance: TabAppearance = TabAppearance.Underlined,
  size: TabSize = TabSize.Default
): string {
  if (appearance === TabAppearance.Pill) {
    switch (size) {
      case TabSize.Small:
        return `0 ${theme.spacingDefault}px`;
      default:
        return `0 ${theme.spacingComfortable}px`;
    }
  }
  if (appearance === TabAppearance.Filled) {
    switch (size) {
      case TabSize.Small:
        return `0 ${theme.spacingDefault}px`;
      case TabSize.Default:
        return `0 ${theme.spacingDefault}px`;
      case TabSize.Large:
        return `0 ${theme.spacingComfortable}px`;
      default:
        throw new Error(`Unknown size: ${size}`);
    }
  }
  return '0';
}

function getFontWeight(theme: DefaultTheme, appearance: TabAppearance = TabAppearance.Underlined): string {
  return appearance === TabAppearance.Pill ? theme.fontWeightMedium : theme.fontWeightRegular;
}

const tab = css<TabProps>`
  border: 0;
  text-decoration: none;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colorTabSelected : theme.colorTab)};
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  appearance: none;
  height: ${({ theme, appearance, size }) => getTabHeight(theme, appearance, size)};
  user-select: none;
  font-weight: ${({ theme, appearance }) => getFontWeight(theme, appearance)};
  padding: ${({ theme, appearance, size }) => getPadding(theme, appearance, size)};
  position: relative;
  font-size: ${({ theme, appearance, size }) => getFontSize(theme, appearance, size)};
  transition: color 200ms;

  &:hover {
    color: ${({ theme, isSelected }) => (isSelected ? theme.colorTabSelected : theme.colorTabHover)};
  }

  &:active {
    color: ${({ theme }) => theme.colorTabActive};
  }

  ${({ theme }) => `
    > span + i, > i + span {
      margin-left: ${theme.spacingSmall}px;
    }
  `};

  &:before {
    bottom: -1px;
    border-bottom: ${({ theme, appearance, isSelected, isDragging }) =>
      isSelected && isDragging && appearance !== TabAppearance.Filled
        ? `2px solid ${theme.borderColorTabSelected}`
        : '0'};
    content: ' ';
    position: absolute;
    right: 0;
    width: 100%;
  }
`;

export const NavTabWrapper = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['isSelected'].includes(prop) && defaultValidatorFn(prop),
})<TabProps>`
  ${tab};
  background: transparent;
`;

export const TabWrapper = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['isSelected'].includes(prop) && defaultValidatorFn(prop),
})<TabProps>`
  ${tab};
  height: ${({ theme, appearance, size }) => getTabHeight(theme, appearance, size)};
`;

TabWrapper.defaultProps = {
  background: 'transparent',
};

export const TabPanel = styled(Box)<{ renderInBackground?: boolean }>`
  flex: 1 1 0;
  ${({ renderInBackground }) =>
    renderInBackground &&
    css`
      flex: 0;
    `};
`;

export const TabsWrapper = styled.div<{ appearance?: TabAppearance }>`
  display: flex;
  list-style: none;
  flex: 0 0 auto;
  margin: 0;
  position: relative;
  width: auto;
  z-index: 1;
  align-items: flex-end;
  padding: 0
    ${({ theme, appearance }) =>
      appearance === TabAppearance.Underlined ? parseDimension(theme, theme.spacingComfortable) : undefined};
`;

export const TabListWrapper = styled(Box)<{ appearance?: TabAppearance; size?: TabSize }>`
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  width: 100%;

  height: ${({ theme, size, h }) => (h ? parseDimension(theme, h) : getTabListHeight(theme, size))};
  background: ${({ theme, appearance }) =>
    appearance === TabAppearance.Filled
      ? theme.backgroundFilledTabList
      : appearance === TabAppearance.Pill
      ? theme.backgroundToggle
      : undefined};

  ${({ appearance, theme }) =>
    appearance === TabAppearance.Pill &&
    `
    border-radius: ${theme.borderRadiusDefault}px;
    border: ${theme.spacingTiny}px solid transparent;
  `};

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
`;

export const TabBorder = styled.div<{ isBordered: boolean }>`
  ${({ isBordered, theme }) => isBordered && `background: ${theme.borderColorTab}`};
  height: 1px;
  margin-top: -1px;
`;

export const TabPanelsWrapper = Box;

export const TabIndicator = styled.div.attrs({
  'data-testid': 'tab-indicator',
})<{
  translateX: number;
  width: number;
  appearance?: TabAppearance;
  isDragging?: boolean;
}>`
  height: ${({ appearance }) => (appearance === TabAppearance.Underlined ? '2px' : `100%`)};
  width: 100px;
  background: ${({ theme, appearance }) =>
    appearance === TabAppearance.Underlined
      ? theme.borderColorTabSelected
      : appearance === TabAppearance.Filled
      ? theme.backgroundFilledTab
      : theme.backgroundPriorityButton};
  border-radius: ${({ theme, appearance }) =>
    appearance === TabAppearance.Pill && theme.borderRadiusDefault - theme.spacingTiny}px;
  ${({ theme, appearance }) =>
    appearance === TabAppearance.Pill && `border: solid  1px ${theme.borderColorPriorityButton};`}
  bottom: 0;
  transition: background 200ms 0s ease, transform 300ms 0s cubic-bezier(0.46, 0.75, 0.36, 1.06);
  transform: ${({ translateX = 0, width = 0, appearance }) =>
    `translateX(${translateX}px) ${appearance === TabAppearance.Filled ? 'translateY(-100%)' : ''} scaleX(${width}%)`};
  transform-origin: left;
  position: ${({ appearance }) => `${appearance !== TabAppearance.Filled ? 'absolute' : 'static'}`};
  display: ${({ isDragging }) => `${isDragging ? 'none' : 'block'}`};
  box-shadow: ${({ theme, appearance }) =>
    appearance === TabAppearance.Filled || appearance === TabAppearance.Underlined ? theme.boxShadowTabActive : 'none'};
`;

const tabEditorBackground = keyframes`
  0% {
    transform: scaleX(90%);
    opacity: 0.5;
  }
  100% {
    transform: scaleX(100%);
    opacity: 1;
  }
`;

export const TabEditorWrapper = styled.div`
  animation: ${tabEditorBackground} 200ms;
  display: flex;
  transform-origin: left;
  background: ${({ theme }) => theme.backgroundTabInput};
  padding-left: ${({ theme }) => `${theme.spacingDefault}px`};
  align-items: center;
  align-self: stretch;
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  cursor: text;

  > span {
    border: 0;
    outline: 0;
    appearance: none;
    min-width: 20px;
    display: block;
    overflow-x: auto;
    padding-right: ${({ theme }) => `${theme.spacingSmall}px`};
  }

  button {
    background: transparent;
    border: 0;
    outline: 0;
    appearance: none;
    height: 100%;
    padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    color: ${({ theme }) => theme.colorTabInputButton};
    &:hover {
      background: transparent;
      color: ${({ theme }) => theme.colorTabInputButtonHover};
    }
    &:active {
      background: transparent;
      color: ${({ theme }) => theme.colorTabInputButtonActive};
    }
  }
`;

export const RightItems = styled.div`
  margin-left: auto;
  align-self: center;
`;

export const OverflowWrapper = styled.div`
  margin: ${({ theme }) => `0 -${theme.spacingDefault}px`};
  ${TabWrapper} {
    width: 100%;

    ${HStack} {
      &:first-child {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`;

export const CloseIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.spacingSmall}px;
  &:hover {
    color: ${({ theme }) => theme.colorTextAttention};
  }
`;
