import { useCallback, type PropsWithChildren, type ReactNode } from 'react';
import { NavLink, useLocation, type NavLinkProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useMixpanel } from '../../contexts';
import { useDynamicCallback } from '../../hooks';
import type { MixpanelEvent } from '../../tokens';
import { HStack } from '../Core';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { useSidebarContext } from './SidebarContext';

export type SidebarItemProps = PropsWithChildren<{
  icon?: IconName | ReactNode;
  to?: NavLinkProps['to'];
  onClick?: () => void;
  isActive?: boolean;
  target?: NavLinkProps['target'];
  hasSubNavItems?: boolean;
  id?: string; // For Support Intercom Button
  showDot?: boolean;
  mixpanelEvent?: MixpanelEvent;
  disableHoverStyles?: boolean;
  style?: React.CSSProperties;
  /** Indent item to align with SidebarTitle text next to an icon */
  menuIndent?: boolean;
}>;

export const SidebarItem = styled(function SidebarItem({
  to,
  icon,
  children,
  hasSubNavItems = false,
  onClick = () => null,
  showDot = false,
  mixpanelEvent,
  menuIndent,
  style,
  ...props
}: SidebarItemProps) {
  const theme = useTheme();
  const location = useLocation();
  const { isExpanded } = useSidebarContext();
  const mixpanel = useMixpanel();

  const getMainPath = useCallback(() => {
    // /dealer/monitoring -> /dealer
    return `/${location.pathname.split('/')[1]}`;
  }, [location.pathname]);

  const handleNavClick = useDynamicCallback(() => {
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent);
    }
    onClick?.();
  });

  const isExternal = typeof to === 'string' && (to.startsWith('http') || to.startsWith('//'));
  return (
    <SidebarItemWrapper
      as={to == null ? HStack : isExternal ? 'a' : NavLink}
      href={isExternal ? to : undefined}
      onClick={handleNavClick}
      // For example, don't re-navigate to Monitor when clicking Dealer if another sub tab is already selected.
      to={to === getMainPath() ? location.pathname + location.search : to}
      exact={!hasSubNavItems}
      style={style}
      {...props}
    >
      {icon ? (
        <IndicatorDotWrapper show={showDot} variant={IndicatorDotVariants.Notification}>
          {typeof icon === 'string' ? <Icon icon={icon as IconName} size={theme.baseSize} /> : icon}
        </IndicatorDotWrapper>
      ) : menuIndent ? (
        <Icon icon={IconName.Connector} color={theme.colors.gray['070']} size={theme.baseSize} />
      ) : null}

      <HStack h="100%" w="100%" justifyContent="space-between">
        {isExpanded ? children : null}
        {isExpanded && hasSubNavItems && <Icon icon={IconName.ArrowRight} size={theme.baseSize} />}
      </HStack>
    </SidebarItemWrapper>
  );
})``;

export const SidebarItemWrapper = styled.button<{ isActive?: boolean; disableHoverStyles?: boolean }>`
  background: transparent;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacingDefault}px;
  padding: 0px ${({ theme }) => theme.spacingDefault}px;
  transition: 200ms;
  text-decoration: none;
  cursor: ${({ disableHoverStyles }) => (disableHoverStyles ? 'default' : 'pointer')};
  line-height: 1;
  color: ${({ theme }) => theme.colorTextSidebarItem};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  width: 100%;
  min-height: ${({ theme }) => theme.baseSize * 2}px;

  &:hover {
    background: ${({ theme, disableHoverStyles }) =>
      disableHoverStyles ? 'inherit' : theme.backgroundSidebarItemHover};
    color: ${({ theme, disableHoverStyles }) => (disableHoverStyles ? 'inherit' : theme.colorTextSidebarItemHover)};
  }

  &.active,
  &.active:hover {
    background: ${({ theme, disableHoverStyles }) =>
      disableHoverStyles ? 'inherit' : theme.backgroundSidebarItemActive};
    color: ${({ theme, disableHoverStyles }) => (disableHoverStyles ? 'inherit' : theme.colorTextSidebarItemActive)};
  }
  ${({ isActive }) =>
    isActive &&
    `
&, &:hover {
background: ${({ theme, disableHoverStyles }) => (disableHoverStyles ? 'inherit' : theme.backgroundSidebarItemActive)};
color: ${({ theme, disableHoverStyles }) => (disableHoverStyles ? 'inherit' : theme.colorTextSidebarItemActive)};
}
`};
`;
