import Big from 'big.js';
import { useMemo } from 'react';

import { defineMessages } from 'react-intl';
import { useTheme, type DefaultTheme } from 'styled-components';
import { getIntlKey } from '../../contexts';
import { useCountdownTime } from '../../hooks/useCountdownTime';
import { useIntl } from '../../hooks/useIntl';
import { DecisionStatusEnum, OrdStatusEnum, PricingModeEnum } from '../../types/types';
import { formattedOrderTime, isDateInThePast, isOrderPaused } from '../../utils';
import { isOrderComplete } from '../../utils/isOrderComplete';
import { orderStatusTextToColor } from '../../utils/order';
import { Box } from '../Core';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Tooltip } from '../Tooltip';
import { Wrapper } from './styles';
import { OrderStatusSize, OrderStatusText } from './types';

const messages = defineMessages({
  open: {
    defaultMessage: 'Open',
    id: 'Status.open',
  },
  filled: {
    defaultMessage: 'Filled',
    id: 'Status.filled',
  },
  rejected: {
    defaultMessage: 'Rejected',
    id: 'Status.rejected',
  },
  systemPaused: {
    defaultMessage: 'System Paused',
    id: 'Status.systemPaused',
  },
  paused: {
    defaultMessage: 'Paused',
    id: 'Status.paused',
  },
  staged: {
    defaultMessage: 'Staged',
    id: 'Status.staged',
  },
  waiting: {
    defaultMessage: 'Waiting',
    id: 'Status.waiting',
  },
  filledAndCanceled: {
    defaultMessage: 'Filled & Canceled',
    id: 'Status.filledAndCanceled',
  },
  canceled: {
    defaultMessage: 'Canceled',
    id: 'Status.canceled',
  },
  pendingCancel: {
    defaultMessage: 'Pending Cancel',
    id: 'Status.pendingCancel',
  },
  pendingReplace: {
    defaultMessage: 'Pending Replace',
    id: 'Status.pendingReplace',
  },
  pendingFix: {
    defaultMessage: 'Pending Fix',
    id: 'Status.pendingFix',
  },
  allCanceled: {
    defaultMessage: 'All Canceled',
    id: 'Status.allCanceled',
  },
  allFilled: {
    defaultMessage: 'All Filled',
    id: 'Status.allFilled',
  },
  allOpen: {
    defaultMessage: 'All Open',
    id: 'Status.allOpen',
  },
  allRejected: {
    defaultMessage: 'All Rejected',
    id: 'Status.allRejected',
  },
  doneForDay: {
    defaultMessage: 'Done For Day',
    id: 'Status.doneForDay',
  },
  holding: {
    defaultMessage: 'Holding',
    id: 'Status.holding',
  },
  new: {
    defaultMessage: 'New',
    id: 'Status.new',
  },
  partiallyFilled: {
    defaultMessage: 'Partially Filled',
    id: 'Status.partiallyFilled',
  },
  pendingNew: {
    defaultMessage: 'Pending New',
    id: 'Status.pendingNew',
  },
  replaced: {
    defaultMessage: 'Replaced',
    id: 'Status.replaced',
  },
});
export const orderStatusMessages = messages;

const orderStatusTextMapping = {
  [OrdStatusEnum.Filled]: OrderStatusText.Filled,
  [OrdStatusEnum.PendingCancel]: OrderStatusText.PendingCancel,
  [OrdStatusEnum.PendingReplace]: OrderStatusText.PendingReplace,
  [OrdStatusEnum.Rejected]: OrderStatusText.Rejected,
  [OrdStatusEnum.New]: OrderStatusText.Open,
  [OrdStatusEnum.PartiallyFilled]: OrderStatusText.Open,
  [OrdStatusEnum.PendingNew]: OrderStatusText.Open,
  [OrdStatusEnum.Holding]: OrderStatusText.Open,
  [OrdStatusEnum.Replaced]: OrderStatusText.Open,
  [OrdStatusEnum.PendingFix]: OrderStatusText.PendingFix,
} satisfies Partial<Record<OrdStatusEnum, string>>;

export const getOrderStatusText = ({
  ordStatus,
  orderQty,
  cumQty,
  decisionStatus,
  pricingMode,
}: {
  ordStatus: OrdStatusEnum;
  orderQty: string;
  cumQty: string;
  decisionStatus?: DecisionStatusEnum;
  pricingMode?: PricingModeEnum;
}): OrderStatusText => {
  if (!isOrderComplete(ordStatus)) {
    if (decisionStatus === DecisionStatusEnum.Paused) {
      return OrderStatusText.Paused;
    }
    if (decisionStatus === DecisionStatusEnum.SystemPaused) {
      return OrderStatusText.SystemPaused;
    }
    if (decisionStatus === DecisionStatusEnum.Staged) {
      return OrderStatusText.Staged;
    }
    if (
      decisionStatus &&
      [DecisionStatusEnum.WaitingForTrigger, DecisionStatusEnum.WaitingForStartTime].includes(decisionStatus)
    ) {
      return OrderStatusText.Waiting;
    }
  }

  if (pricingMode === PricingModeEnum.SpreadToFixing && ordStatus === OrdStatusEnum.Filled) {
    return OrderStatusText.PendingFix;
  }

  if (ordStatus === OrdStatusEnum.Canceled) {
    return Big(cumQty || 0).gt(0) ? OrderStatusText.FilledAndCanceled : OrderStatusText.Canceled;
  }

  if (ordStatus === OrdStatusEnum.DoneForDay) {
    if (Big(cumQty || 0).eq(0)) {
      return OrderStatusText.Canceled;
    }
    // Synthetic ccy offers sometime fill over
    return Big(cumQty || 0).gte(orderQty) ? OrderStatusText.Filled : OrderStatusText.FilledAndCanceled;
  }

  const orderStatusText = orderStatusTextMapping[ordStatus];
  if (orderStatusText == null) {
    // TODO logger.warn...
    return OrderStatusText.Open;
  }

  return orderStatusText;
};

const StatusIcon = ({ orderStatusText, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const color = orderStatusTextToColor(orderStatusText, useTheme() ?? theme);
  switch (orderStatusText) {
    case OrderStatusText.Filled:
    case OrderStatusText.FilledAndCanceled:
      return <Icon icon={IconName.CheckCircleSolid} color={color} {...props} />;
    case OrderStatusText.Canceled:
      return <Icon icon={IconName.CloseCircleSolid} color={color} {...props} />;
    case OrderStatusText.Rejected:
      return <Icon icon={IconName.ExclamationCircleSolid} color={color} {...props} />;
    case OrderStatusText.Paused:
    case OrderStatusText.SystemPaused:
      return <Icon icon={IconName.PauseCircle} color={color} {...props} />;
    case OrderStatusText.Staged:
      return <Icon icon={IconName.CircleDotted} color={color} {...props} />;
    case OrderStatusText.PendingCancel:
    case OrderStatusText.PendingReplace:
    case OrderStatusText.Waiting:
      return <Icon icon={IconName.Clock} color={color} {...props} />;
    default:
      return <Icon icon={IconName.Circle} color={color} {...props} />;
  }
};

type OrderStatusProps = {
  ordStatus: OrdStatusEnum;
  decisionStatus?: DecisionStatusEnum;
  cumQty?: string;
  orderQty?: string;
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  tooltipText?: string;
  theme?: DefaultTheme;
  title?: string;
  startTime?: string;
  endTime?: string;
  size?: OrderStatusSize;
  pricingMode?: PricingModeEnum;
};

const StatusText = ({
  useStartTime,
  formattedCountdown,
  orderStatusText,
}: {
  useStartTime: boolean;
  formattedCountdown: string | undefined;
  orderStatusText: OrderStatusText;
}) => {
  const { formatMessage } = useIntl();
  const intlKey = getIntlKey(orderStatusText);
  let orderStatusTextTranslated: string = orderStatusText;
  if (intlKey in messages) {
    orderStatusTextTranslated = formatMessage(messages[intlKey]);
  }
  return (
    <>
      {formattedCountdown ? `${useStartTime ? 'Starts' : 'Ends'} in ${formattedCountdown}` : orderStatusTextTranslated}
    </>
  );
};

export function OrderStatus({
  ordStatus,
  decisionStatus,
  pricingMode,
  cumQty = '0',
  orderQty = '0',
  align = 'right',
  tooltipText,
  startTime,
  endTime,
  size = OrderStatusSize.Default,
  ...props
}: OrderStatusProps) {
  const orderStatusText: OrderStatusText = getOrderStatusText({
    ordStatus,
    decisionStatus,
    cumQty,
    orderQty,
    pricingMode,
  });
  let variant = IndicatorDotVariants.Warning;
  if (tooltipText?.includes('Order has unhedged size on legs')) {
    variant = IndicatorDotVariants.Negative;
  }
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;

  const isPaused: boolean = decisionStatus != null && isOrderPaused(decisionStatus);
  const useStartTime: boolean = startTime != null && !isDateInThePast(startTime);
  const completionTime = useMemo(
    () => Sugar.Date.create(useStartTime ? startTime : endTime),
    [useStartTime, startTime, endTime]
  );
  const orderCountdown = useCountdownTime(completionTime, ordStatus, 1000);
  const formattedCountdown: string | undefined =
    !isOrderComplete(ordStatus) && !isPaused && ((startTime != null && !isDateInThePast(startTime)) || endTime != null)
      ? formattedOrderTime(orderCountdown)
      : undefined;

  const tooltipContent = !!tooltipText && <Box maxWidth="300px">{tooltipText}</Box>;

  return tooltipContent ? (
    <Tooltip tooltip={tooltipContent} usePortal>
      {renderOrderStatus()}
    </Tooltip>
  ) : (
    renderOrderStatus()
  );

  function renderOrderStatus() {
    return (
      <Wrapper iconPlacement="left" align={align} theme={theme} size={size} {...props}>
        <StatusText
          useStartTime={useStartTime}
          formattedCountdown={formattedCountdown}
          orderStatusText={orderStatusText}
        />
        <IndicatorDotWrapper show={!!tooltipText} theme={theme} variant={variant}>
          <StatusIcon
            orderStatusText={orderStatusText}
            theme={theme}
            size={size === OrderStatusSize.Default ? 'fontSizeLarge' : 'fontSizeSmall'}
          />
        </IndicatorDotWrapper>
      </Wrapper>
    );
  }
}
