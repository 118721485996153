// roles
export enum ROLE {
  NONE = '',
  READ_ONLY = 'org.viewer',
  TRADER = 'org.trader',
  TRADING_CONFIG = 'org.trading.config',
  ADMIN = 'org.admin',
  USER_ADMIN = 'org.user.admin',
  CREDENTIALS_ADMIN = 'org.credentials.admin',
  DEALER = 'org.dealer',
  DEALER_VIEWER = 'org.dealer.viewer',
  DEALER_ADMIN_OPS = 'org.dealer.admin.ops',
  TRANSFERS = 'org.transfers',
  TRANSFERS_HIDDEN = 'org.transfers.hidden',
  TALOS_SUPPORT = 'talos.support',
  TALOS_ADMIN = 'talos.admin',
  TALOS_ONBOARDING_ADMIN = 'talos.onboarding.admin',
  TALOS_TEST_RUNNER = 'talos.testrunner',
  TALOS_VIEWER = 'talos.viewer',
  TALOS_CANCEL_ORDERS = 'talos.orders.cancel',
  EXCHANGE_SETTLEMENT = 'exchange.settlement',
  CUSTODIAN_SETTLEMENT = 'custodian.settlement',
  MARKETPLACE_VIEWER = 'marketplace.viewer',
  MARKETPLACE_TRADER = 'marketplace.trader',
  MARKETPLACE_ADMIN = 'marketplace.admin',
  PORTFOLIO = 'org.portfolio',
  PORTFOLIO_BETA = 'org.portfolio.beta',
  ANALYTICS = 'org.analytics',
  TREASURY = 'org.treasury',
  SECMASTER = 'org.secmaster',
  MARKETPLACE_OPS = 'marketplace.ops',
  TRADING_CONTROLS_ADMIN = 'org.trading.controls.admin',
  PORTFOLIO_ENGINEERING = 'org.portfolio.engineering',
  PORTFOLIO_RECON = 'org.portfolio.recon',
  AUTOHEDGING_VIEW = 'org.autohedger.view',
  AUTOHEDGING_ADMIN = 'org.autohedger.admin',
  DEALER_CONFIGURATION = 'org.dealer.configuration',
}

// actions
export enum ACTION {
  VIEW_ORDER_FORM = 'order-form.view',
  VIEW_RFQ_FORM = 'rfq-form.view',
  VIEW_SALES_ORDER_FORM = 'sales-order-form.view',
  VIEW_SALES_RFQ_FORM = 'sales-rfq-form.view',
  VIEW_ORDER = 'order.view',
  VIEW_AUTOHEDGING = 'autohedging.view',
  EDIT_AUTOHEDGING = 'autohedging.edit',
  SUBMIT_ORDER = 'order.submit',
  PAUSE_ORDER = 'order.pause',
  RESUME_ORDER = 'order.resume',
  CANCEL_ORDER = 'order.cancel',
  ARCHIVE_ORDER = 'order.archive',
  REQUEST_RFQ = 'rfq.request',
  ACCEPT_RFQ = 'rfq.accept',
  EDIT_TRADE = 'trade.edit',
  EDIT_MARKET_SETTINGS = 'settings.markets.edit',
  EDIT_AGGREGATION_SETTINGS = 'settings.aggregations.edit',
  EDIT_SUB_ACCOUNTS = 'settings.subaccounts.edit',
  CLONE_SUB_ACCOUNTS = 'settings.subaccounts.clone',
  EDIT_SECMASTER = 'settings.secmaster.edit',
  EDIT_SECMASTER_ADVANCED = 'settings.secmaster.editadvanced',
  SUBMIT_TRANSFER = 'transfer.submit',
  EDIT_LIMITS = 'limits.edit',
  EDIT_MAX_ORDER_SIZE_LIMITS = 'limits.max-order-size.edit',
  EDIT_COUNTERPARTY_LIMITS = 'limits.counterparty.edit',
  TOGGLE_KILLSWITCH = 'killswitch.toggle',
  EDIT_BALANCE = 'balance.edit',
  VIEW_DEALER = 'dealer.view',
  APPROVE_PENDING_TRANSACTION = 'dealer.approve.transaction',
  APPROVE_PENDING_TRANSFER = 'dealer.approve.transfer',
  FORCE_WITHDRAWAL = 'dealer.force-withdrawal',
  DEALER_TRADING = 'dealer.trading',
  VIEW_FIX_CONNECTIONS = 'dealer.fixconnections.view',
  EDIT_DEFAULT_PRICING_RULE = 'dealer.edit.default-pricing-rule',
  EDIT_EXECUTION_RULES_MODE = 'dealer.edit.execution-rules-mode',
  EDIT_CUSTOMER_BALANCE = 'dealer.edit.customer-balance',
  EDIT_DEALER_TRADING = 'dealer.edit.trading',
  EDIT_POSITION = 'position.edit',
  VIEW_ORDER_ANALYTICS = 'orderanalytics.view',
  VIEW_PORTFOLIO = 'portfolio.view',
  VIEW_TALOS_ADMIN_SETTINGS = 'talos.admin.view',
  EDIT_FILTER_PERMISSIONS = 'settings.filterpermissions.read',
  EDIT_ROLLUPS = 'settings.rollups.edit',
  EDIT_MARKET_SESSION_LIMIT = 'settings.users.edit.market-session-limit',
  EDIT_MARKET_CREDENTIALS = 'settings.users.edit.market-credentials',
  EDIT_ADDRESSES = 'settings.addresses.edit',
  MANUAL_ORDER_FILL = 'manual.order.fill',
  MANUAL_RFQ_PRICING = 'manual.rfq.pricing',
  EDIT_USERS = 'settings.users.edit',
  HEDGER_SYNCHRONIZATION = 'hedger.synchronization',
  VIEW_LEDGER_EVENTS = 'ledger.events.view',
  VIEW_CUSTOMER_CONFIGURATIONS = 'dealer.configurations.view',
  EDIT_CUSTOMER_CONFIGURATIONS = 'dealer.configurations.edit',
  VIEW_CUSTOMER_PRICING_TIERS = 'dealer.pricing-tiers.view',
  EDIT_CUSTOMER_PRICING_TIERS = 'dealer.pricing-tiers.edit',
  VIEW_CUSTOMER_SYMBOL_GROUPS = 'dealer.symbol-groups.view',
  EDIT_CUSTOMER_SYMBOL_GROUPS = 'dealer.symbol-groups.edit',

  VIEW_MONITORING_ORDER_CONTEXT = 'monitoring.ordercontext.view',
  /**
   * Bypass filter permissions and be allowed to trade on all subaccounts
   */
  VIEW_FILTER_PERMISSIONS_FEATURE_DISABLED = 'settings.filterpermissions.viewfeaturedisabled',
  VIEW_ANALYTICS = 'analytics.view',
  /**
   * View the ApiFilterPermissions even if the flag `'trading.EnableAccountSegregation'` is disabled
   */
  BYPASS_FILTER_PERMISSIONS_RESTRICTIONS_SUBACCOUNTS = 'principal.filterpermissions.bypass.subaccounts',

  // Talos-specific permissions
  FORCE_CANCEL_ORDER = 'order.force-cancel',
  FORCE_FILL_ORDER = 'order.force-fill',
  USE_ORGANIZATION = 'organization.use',
  EDIT_CONFIGURATIONS = 'settings.configurations.edit',
  EDIT_ORGANIZATIONS = 'organizations.edit',
  EDIT_MARKET_ACCOUNTS = 'market-accounts.edit',
  EDIT_COUNTERPARTIES = 'counterparties.edit',
  EDIT_MARKET_CONNECTIONS = 'market-connections.edit',
  EDIT_PROCESSES = 'processes.edit',
  MORPH_USER = 'user.morph',

  // Credentials specific
  VIEW_CREDENTIALS = 'settings.credentials.view',
  EDIT_CREDENTIALS = 'settings.credentials.edit',

  // Trading Controls
  VIEW_TRADING_CONTROLS = 'settings.trading-controls.view',
  VIEW_ALL_MARKET_ACCOUNTS_IN_TRADING_CONTROLS = 'settings.trading-controls.view.all.market.accounts',
  REQUEST_EQUITY_MARGIN_RATE_LIMIT = 'equity-margin-ratio-limit.request',
  VIEW_TRADING_CONTROLS_DIALOG = 'trading-controls-dialog.view',

  // Portfolio Engineering
  EDIT_PORTFOLIO_ENGINEERING = 'portfolio-engineering.edit',

  SUBMIT_CARE_ORDER = 'careOrder.submit',

  VIEW_PORTFOLIO_RECON = 'portfolio.recon.view',
  EDIT_PORTFOLIO_RECON = 'portfolio.recon.edit',

  // Account Segregaation

  /** Show all market accounts regardless of MarketAccounts Read Permission */
  VIEW_ALL_MARKET_ACCOUNTS_IN_ACCOUNT_SEG = 'settings.account-segregation.view.all.market.accounts',
}

//marketplace actions
export enum MARKETPLACE_ACTION {
  VIEW_LOAN_QUOTE_REQUEST = 'loanQuote.view',
  SUBMIT_LOAN_QUOTE_REQUEST = 'loanQuote.submit',
  SUBMIT_CLOSE_LOAN = 'loan.submitClose',
  EDIT_CREDENTIALS = 'credentials.edit',
  SUBMIT_INITIATE_TRANSFER = 'transaction.submitTransfer',
}
