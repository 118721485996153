import { useTheme, type DefaultTheme } from 'styled-components';

import { QuoteStatusEnum } from '../../types/types';
import { Box } from '../Core';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Tooltip } from '../Tooltip';
import { Wrapper } from './styles';
import { QuoteStatusText } from './types';

const StatusIcon = ({ quoteStatus, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { colorTextPositive, colorTextWarning, colorTextDefault } = theme;
  switch (quoteStatus) {
    case QuoteStatusEnum.PendingFix:
      return <Icon icon={IconName.CheckCircle} color="var(--colors-blue-lighten)" {...props} />;
    case QuoteStatusEnum.Filled:
      return <Icon icon={IconName.CheckCircleSolid} color={colorTextPositive} {...props} />;
    case QuoteStatusEnum.Canceled:
    case QuoteStatusEnum.Closed:
      return <Icon icon={IconName.CloseCircleSolid} color={colorTextDefault} {...props} />;
    case QuoteStatusEnum.Rejected:
    case QuoteStatusEnum.Excluded:
      return <Icon icon={IconName.ExclamationCircleSolid} color={colorTextWarning} {...props} />;
    default:
      return <Icon icon={IconName.Circle} color="var(--colors-blue-lighten)" {...props} />;
  }
};

type QuoteStatusProps = {
  quoteStatus: QuoteStatusEnum;
  align?: 'right' | 'left';
  tooltipText?: string;
  theme?: DefaultTheme;
  iconPlacement?: 'right' | 'left';
};

const quoteStatusTextMapping = {
  [QuoteStatusEnum.PendingNew]: QuoteStatusText.PendingNew,
  [QuoteStatusEnum.Open]: QuoteStatusText.Open,
  [QuoteStatusEnum.PendingCancel]: QuoteStatusText.PendingCancel,
  [QuoteStatusEnum.Canceled]: QuoteStatusText.Canceled,
  [QuoteStatusEnum.PendingFill]: QuoteStatusText.Open,
  [QuoteStatusEnum.Filled]: QuoteStatusText.Filled,
  [QuoteStatusEnum.Closed]: QuoteStatusText.Rejected,
  [QuoteStatusEnum.Excluded]: QuoteStatusText.Rejected,
  [QuoteStatusEnum.Rejected]: QuoteStatusText.Rejected,
  [QuoteStatusEnum.Holding]: QuoteStatusText.Open,
  [QuoteStatusEnum.TransientError]: QuoteStatusText.Open,
  [QuoteStatusEnum.PendingFix]: QuoteStatusText.PendingFix,
} satisfies Record<QuoteStatusEnum, string>;

function getQuoteStatusText({ quoteStatus }: { quoteStatus: QuoteStatusEnum }) {
  return quoteStatusTextMapping[quoteStatus];
}

export function QuoteStatus({ quoteStatus, theme, align = 'right', tooltipText, ...props }: QuoteStatusProps) {
  if (quoteStatus == null) {
    return null;
  }
  const quoteStatusText = getQuoteStatusText({ quoteStatus });

  const tooltipContent = !!tooltipText && <Box maxWidth="300px">{tooltipText}</Box>;

  return tooltipContent ? (
    <Tooltip tooltip={tooltipContent} usePortal>
      {renderQuoteStatus()}
    </Tooltip>
  ) : (
    renderQuoteStatus()
  );

  function renderQuoteStatus() {
    return (
      <Wrapper iconPlacement="left" align={align} title={tooltipText} theme={theme} {...props}>
        {quoteStatusText}

        <IndicatorDotWrapper show={!!tooltipText} theme={theme} variant={IndicatorDotVariants.Warning}>
          <StatusIcon quoteStatus={quoteStatus} theme={theme} size="fontSizeLarge" />
        </IndicatorDotWrapper>
      </Wrapper>
    );
  }
}
