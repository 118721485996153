import { useCallback } from 'react';

import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  FormControlSizes,
  FormattedMessage,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  useMixpanel,
} from '@talos/kyoko';

import { BlotterActions } from '../styles';

import { defineMessages } from 'react-intl';
import type { TabbedBlotterProps } from '../types';
import { useActiveOrdersBlotter } from './hooks/useActiveOrdersBlotter';

const messages = defineMessages({
  archiveAll: {
    id: 'Blotters.ActiveOrdersBlotter.archiveAll',
    defaultMessage: 'Archive All',
  },
});

export type ActiveOrdersBlottersProps = Required<TabbedBlotterProps>;

export const ActiveOrdersBlotter = ({
  renderInBackground: hidden,
  loading,
  maxRows,
  onStartLoading,
  onFinishedLoading,
}: ActiveOrdersBlottersProps) => {
  const mixpanel = useMixpanel();

  const { archiveAll, blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useActiveOrdersBlotter({
    maxRows,
  });

  const handleArchiveAllOrders = useCallback(() => {
    onStartLoading();
    archiveAll();
    /**
     * The archiveAll function executes fairly instantaneously, so the
     * loading spinner doesn't really have a chance to display. In this
     * case, we should artificially delay the onFinishedLoading callback
     * for half a second, just to at least give the user a visual cue.
     */
    setTimeout(onFinishedLoading, 500);
    mixpanel.track(MixpanelEvent.ClearAllOrders);
  }, [archiveAll, mixpanel, onStartLoading, onFinishedLoading]);

  return (
    <>
      {!hidden && (
        <>
          <BlotterTableFilters
            {...filterBuilderAccordion}
            {...blotterTableFilterProps}
            {...blotterTable.blotterTableFiltersProps}
            prefix={
              <BlotterActions>
                <Button size={FormControlSizes.Small} loading={loading} onClick={handleArchiveAllOrders}>
                  <FormattedMessage {...messages.archiveAll} />
                </Button>
              </BlotterActions>
            }
          />
        </>
      )}
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
    </>
  );
};
